@import '../../Aiva_Colors.scss';

.Replies {
  display: flex;

  &__border_preview {
    width: 10px;

    background: transparent;
    align-self: stretch;
    display: flex;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }

  &__border_preview_line {
    width: 1px;
    height: 100%;
    background: $border-grey;
  }

  &--message_board {
    margin-left: 24px;
    padding-left: 4px;
  }
}