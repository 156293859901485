.ChatBox {
  position: relative;
  padding: 0px 10px 10px 10px;
  width: 100%;

  &__textarea {
    width: 100%;
    resize: none;
    border-radius: 3px;
    padding: 12px 0 0 12px;
  }
}