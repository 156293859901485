.MarketCell {
  margin: 10px 10px;
}

$gold: #cc9900;
$gray: #6c757d;

.ScreensCell {
  display: flex;
  justify-content: space-evenly;
  color: white;

  &--gold {
    border: 1px solid $gold;
    box-sizing: border-box;
    border-radius: 2px;
  }
}

.Best {
  display: flex;
  flex-direction: column;
  border-radius: 2px;
  width: 100%;
  color: black;
  transition: transform .2s;

  &--interest {
    background-color: #90b8f8;
  }

  &--interest:hover, &--no_interest:hover {
    background-color: #b7d1fa;
    cursor: pointer;
    transform: scale(1.1);
  }

  &--bid_screen {
    border-right: 1px solid $gray;

    &--gold {
      border-right: 1px solid $gold;
    }
  }

  > div {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }

  &__price {
    height: 30px;

    &--interest{
      color: black;
      font-size: 20px;
      font-weight: bold;
    }

    font-size: 16px;
    color: white;

    &--screen--gold {
      color: $gold;
    }
  }

  &__size {
    height: 25px;
    font-size: 14px;

    &--interest{
      border-top: 1px solid white;
    }

    &--screen {
      border-top: 1px solid $gray;
      color: #cccccc;
    }
  }
}