/*.org-dtl-wrapper{*/
/*    display: flex;*/
/*    flex-direction: row;*/
/*    justify-content: space-evenly;*/
/*}*/

/*.org-dtl-grid-wrapper{*/
/*    display: flex;*/
/*    flex: 1 1 auto;*/
/*    margin: 10px;*/
/*}*/

.admin-table {
    background-color:white;
    width: 400px;
    margin: 30px;
    border: 1px solid grey;
}
.admin-table tr:nth-child(first-child){
    color:blue;
}

.admin-table td {
    padding: 5px;
}
.admin-table:nth-child(even) {
    background-color: rgba(255,255,255,.1);
}

.admin-table td:nth-child(1){

}

.admin-wrapper div.k-tabstrip-top{
    background:black;
}
.k-tabstrip>.k-content.k-state-active.org-content{
    /*background-color: blue;*/
}

.admin-wrapper{
    /*margin: 0 auto;*/
    height:100%;
    background:black;
}

.admin-wrapper ul.k-tabstrip-items{
    display:flex;
    justify-content: center;
    background-color: #34adff;
    background-image: -webkit-linear-gradient(150deg,#004080 35%,#06c 0);
    color:white;
}

.k-tabstrip-items .k-item {
    color: white;
}

.admin-wrapper .k-content {
    width: auto;
    margin: 0 auto;
}

.admin-wrapper li:hover{
    transition:.3s;
    background:  #4da6ff;
}
.admin-wrapper li.k-state-active {
    background:  #4da6ff;
}

.admin-btn-panel {
    display: block;
    /*position: fixed;*/
    background-color: black;
    z-index: 3000;
    /*position: fixed;*/
    top: 0;
    /*background-color: yellow;*/
    font-size: 20px;
}

.admin-content{
    margin-top:30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.admin-wrapper .dialog-table input.k-textbox {
    /*height: fit-content;*/
}

/** PERSONS FORM **/
.ac-wrapper{
    width:100%;
    flex-direction:column;
    align-items: center;
    display:flex;
    justify-content: center;
}

.ent-wrapper {
    display:flex;
    flex-direction:row;
    align-items: flex-start;
    justify-content: center;
    width:100%;
    margin-top:30px;
}
.ent-panel{
    /*background-color:white;*/
    /*padding: 30px;*/
    /*border-radius:20px;*/
    margin:0 30px;
}


.admin-wrapper table{background-color:black;}

.ent-wrapper th {
    padding:10px 0px;
    text-align: center;
    background: #4393e6;
    color:white;
    border-radius: 10px 10px 0 0;
}
.ent-wrapper td {
    padding:10px 20px;
}