.Body__dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;

  &--online {
    background: green;
  }

  &--offline {
    background: grey;
  }
}

.Body {
  $root: &;

  &:hover {
    background: #f2f2f2;

    .Body__reply_btn {
      color: cornflowerblue;
    }
  }

  &__metadata {
    padding: 5px;
    display: flex;
    align-items: center;

    #{$root}__username {
      font-weight: bold;
      margin: 0 6px 0 5px;
    }

    #{$root}__createdAt {
      font-size: 12px;
      color: #2b2a2a;
    }
  }

  &__body {
    padding: 5px 0 5px 18px;
    white-space: pre-wrap; // ensures line breaks render
  }

  &__reply_btn {
    font-size: 14px;
    color: transparent;
    margin-left: auto;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  &--thread {
    padding: 4px 10px;
  }
}