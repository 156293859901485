#bap {
  //overflow-y: hidden;

  div.k-grid {
    //height: calc(100vh - 85px);
    height: calc(100vh - 49px)
  }

  div.k-grid-content {
    min-height: 100%;
    //overflow:hidden;
  }

  div.k-grid-header {
    //padding: 0 !important;
  }

  div.k-grid {
    font-size: 14px;
  }

  tr.k-master-row, .k-grid-header-wrap tr {
    //text-align: center;
  }

  .k-grid td {
    //padding: 8px 4px;
    text-overflow: clip;
  }

  .k-grid-content>div {
    border-bottom: 1px solid rgba(255,255,255,.1);
  }
  // button icon
  .icon-button span {
    margin-right: 8px;
  }

  /** Blotter column widths **/
  .k-grid-header col:nth-of-type(1){
    width: 5%
  }
  .k-grid-table col:nth-of-type(1){
    width: 5%
  }

  .k-grid-header col:nth-of-type(2){
    width: 10%
  }
  .k-grid-table col:nth-of-type(2){
    width: 10%
  }

  .k-grid-header col:nth-of-type(3){
    width: 8%
  }
  .k-grid-table col:nth-of-type(3){
    width: 8%
  }

  .k-grid-header col:nth-of-type(4){
    width: 8%
  }
  .k-grid-table col:nth-of-type(4){
    width: 8%
  }

  .k-grid-header col:nth-of-type(5){
    width: 8%
  }
  .k-grid-table col:nth-of-type(5){
    width: 8%
  }

  .k-grid-header col:nth-of-type(7){
    width: 10%
  }
  .k-grid-table col:nth-of-type(7){
    width: 10%
  }

  @media only screen and (max-width: 600px) {
    body {
      background-color: lightblue;
    }
  }

}

/** Strike and leg td cell **/
.strike-flex {
  display: flex;
  flex-flow: row nowrap;
}

.strike-flex > div{

}

.strike-flex > div:first-child {
  flex: 0 1 65%;
  text-align: center;
}

.strike-flex > div:last-child {
  width: 30%;
  text-align: left;
}

td.bold {
  font-weight: bold;
}

/** Stp Window reset **/
div.k-window {
  border:none;
  -webkit-box-shadow: 0px 10px 27px 2px rgba(0,0,0,0.75);
  -moz-box-shadow: 0px 10px 27px 2px rgba(0,0,0,0.75);
  box-shadow: 0px 10px 27px 2px rgba(0,0,0,0.75);
}

div.k-window-titlebar {
  padding: 0 0 0 15px;
  background: rgba(255,255,255,.04);
  font-weight: bold;
}

div.k-window-title {
  font-size: 18px;
}

.k-window-title:active {
  cursor: grab;
}

div.k-window-content {
  padding: 0;
  background: rgba(255,255,255,.04);
  //background: #c1a57b;
}

/** EMAIL BLOTTER **/
#eb-wrapper{
  display: flex;
  flex-flow: row wrap;
}

.email-blotter {
  display: flex;
  flex: 1 1 500px;
  flex-flow: column nowrap;
  margin: 10px 10px 15px 10px;
  //overflow: hidden;

  * {
    //outline: 1px solid aliceblue;
  }

  // HEADER
  .eb-header {
    display: flex;
    flex-flow: row wrap;
    padding: 10px;
  }

  .eb-header {
    //background: #111e22;
  }

  #buy-eb-header {
    background: #c1a57b;
  }

  #sell-eb-header {
    background: #30475e;
  }

  .eb-header-dir {
    font-weight: bold;
    font-size: 12px;
  }

  .eb-header-client {

  }

  .eb-header-org {

  }
  .eb-header-left {

  }
  .eb-header-right {
    display: flex;
    margin-left: auto;
    align-self: center;
  }

  // BODY (Table)
  .eb-body {
    display: flex;

    table {
      width: 100%;
    }
    td {
      padding-left: 10px;
      word-break: break-all;
    }

    .td-email {
      width: 40%;
    }

    .td-status{
      width: 22%;
    }

    .td-sentAt{
      width: 36%;
      text-align:center;
    }

    .td-icon>div{
      margin: 11px;
    }

    tr:nth-child(odd){
      background: rgba(255,255,255,.16);
    }

    tr:nth-child(even) {
      background: rgba(255,255,255,.24);
    }

    tr:hover {
      opacity: .7;
    }
  }

}

/** Date Filter Strip **/
#date-strip {
  // Dropdown
  .k-dropdown .k-dropdown-wrap span.k-input,
  .k-numerictextbox .k-numeric-wrap input.k-input,
  .k-datepicker input.k-input,
  .k-button{
    padding: 4px 10px;
    height: auto;
    background: #0f0f0f;
    font-size: 13px;
  }

  .k-dropdown .k-dropdown-wrap span.k-select {
    padding: 4px;
  }
}