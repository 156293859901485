.OrderRow {
  display: grid;
  grid-template-columns: 20px 1fr 4.5fr 50px 130px 120px 130px;
  grid-template-rows: auto auto 1fr;
  grid-template-areas:
          "plus  client    order    icons     bids    screens   asks"
          ".       .       footer   footer     bid_depth     .  ask_depth"
          ".     detail    detail   detail  bid_depth    .   ask_depth";


  //grid-template-columns: 20px 0.3fr 1fr 4.5fr 50px 130px 120px 130px;
  //grid-template-rows: auto auto 1fr;
  //grid-template-areas:
  //        "plus gauge client    order    icons     bids    screens   asks"
  //        ".      .     .       footer   footer     bid_depth     .  ask_depth"
  //        ".      .   detail    detail   detail  bid_depth    .   ask_depth";

  align-items: center;
  column-gap: 10px;

  &:nth-child(even) {
    background-color: #262626;
  }
  &:nth-child(odd) {
    background: #1a1a1a;
    //background: #2C3849;
  }

  &--inactive:nth-child(even){
    background: #1d2530;
  }
  &--inactive:nth-child(odd){
    background: #263140;
  }

  &__footer {
    align-self: start;
    grid-area: footer;
    margin-left: 60px;
  }

  &__bid_depth {
    align-self: start;
    grid-area: bid_depth;
  }

  &__ask_depth {
    align-self: start;
    grid-area: ask_depth;
  }

  &__plus {
    grid-area: plus;

    &:hover{
      color: #5f85db;
    }
  }

  &__client {
    grid-area: client;
    border-right: 1px solid #333333;
    border-left: 1px solid #333333;
  }

  &__plus,
  &__client {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
  }

  &__gauge {
    grid-area: gauge;
  }

  &__metadata {
    grid-area: metadata;
  }

  &__orderstring {
    grid-area: order;
  }

  &__icons {
    grid-area: icons;
    height: 100%;
  }

  &__bids {
    grid-area: bids;
  }

  &__screens {
    grid-area: screens;
  }

  &__asks {
    grid-area: asks;
  }

  &__plus:hover,
  &__client:hover,
  &__order:hover,
  &__footer:hover {
    cursor: pointer;
  }

  &__detail {
    grid-area: detail;
    align-self: start;
  }

  &:hover {
    background-color: #2C3849;
  }
}