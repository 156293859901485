//$light: #ff69b5;
//$hovered-bg: #ff69b5;
//
//@import "../../node_modules/@progress/kendo-theme-bootstrap/dist/all";
//@import "../../node_modules/bootstrap/scss/bootstrap";

//$grid-bg:  #222222;
//$grid-alt-bg: #263238;
//$grid-hovered-bg: #3282b8;

@import 'variables';

$grid-bg:  #26282b;
$grid-alt-bg: #303236;
$grid-hovered-bg: #5f85db;

// Import the base theme
@import "../../../../node_modules/@progress/kendo-theme-bootstrap/dist/all";
@import "../../../../node_modules/bootstrap/scss/bootstrap";

//@import "../../node_modules/@progress/kendo-theme-bootstrap/dist/all";
