$height: 440px;
$padding: 5px;

@media screen and (max-width: 950px) {
  .Preview {
    left: 20px;
    max-width: 300px;
  }
}

.Preview {
  //&--compact {
  //  left: 20px;
  //}
  //
  //&--default {
  //  left: auto;
  //}

  // this is for BidPreview
  &--reply_box {
    height: 130px;
    top: -130px;
  }

  height: $height;
  position: absolute;
  top: -$height;
  right: 20px;

  background: #2C3849;
  border-radius: 5px 5px 0 0;
  color: white;
  transition:left 1s linear;

  &__body {
    padding: 10px;
  }

  &__td_title {
    text-align: right;
    font-weight: bold;
    font-size: 14px;
    padding-right: 5px;
  }

  overflow-y: auto;
}