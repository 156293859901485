.TradeList {
  margin: 5px 0;
  border-radius: 4px;
  overflow: hidden;
  position: relative;

  >div{
    //outline: 1px solid grey;
  }
  &__row {
    display: flex;
    font-size: 12px;
    padding: 2px 5px;

    &--primary {
      border-left: 2px solid #00b36b;
    }

    &--secondary {
      border-left: 2px solid #ff9900;
    }

    &:nth-child(even) {
      background: #47506c;
    }

    &:nth-child(odd) {
      background: #353C51;
    }

    &:hover{
      background: #5f85db;
    }

  }

  &__date {
    width: 20%;
    min-width:60px;
    max-width: 100px;
  }

  &__price {
    width: 20%;
    min-width:90px;
    max-width: 120px;

    >div {
      display: inline-block;
      width: 40%;
      text-align: right;
    }
  }

  &__slash {
    width: 10px;
    text-align: center;
  }

  &__client {
    width: 20%;
    max-width: 70px;

    &--left{
      text-align: right;
    }

    &--right{
      text-align: left;
    }
   }

  &__icon {
    &:hover{
      cursor: pointer;
    }

    position: absolute;
    right: 5px;
    top: 1px;
  }
}