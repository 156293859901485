@import '../../Aiva_Colors';

.Divider {
  margin: 10px 15px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;

  &__text {
    flex: 1 0 auto;
    padding-right: 10px;
    color: $text_grey;
  }

  &__hr {
    height: 1px;
    background: #c6c8ca;
    width: 100%;
  }
}