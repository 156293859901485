.MetadataCell {
  margin-top: 10px;
  margin-left: 60px;
  display: flex;
  font-size: 12px;

  &__longname {
    color: gainsboro;
  }

  &__industry {
    color: #66b3ff;
  }

  &__sector {
    color: #66b3ff;
    //color: #b3e6ff;
  }
}