.RoomList {
  &__li {
    display: flex;
    align-items: center;

    &:hover {
      background:  #435670;
      cursor: pointer;
    }

    &--selected {
      background: #566e8f;
      color: white;
    }
  }

  &__text {
    padding: 10px;
  }
}