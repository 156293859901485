* {
    /*outline: 1px solid antiquewhite;*/
}

/* INPUT RESETS */
.ticket {
    input.k-textbox, .k-combobox span.k-dropdown-wrap, .k-dateinput span.k-dateinput-wrap {
        background-color: rgba(255, 255, 255, 0.12);
        color: white;
        border: transparent;
    }

    div.k-datetimepicker .k-picker-wrap, .k-datepicker span.k-picker-wrap {
        background: transparent;
    }

    .k-combobox .k-dropdown-wrap span.k-select {
        border-left-width: 0;
        background: none;
        color: white;
    }
}

.dropdown-popup-small .k-item {
    padding: 2px 0 2px 8px;
    font-size: 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button,
input::-webkit-clear-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance:textfield; /* Firefox */
}

/* End input reset */

/* TAB STYLING*/

.tab-wrapper {
    border-bottom: 1px solid #4da6ff;
    background: repeating-linear-gradient(-45deg, black, #121212 100px);
    z-index:100;
}
.react-tabs {
    /*-webkit-tap-highlight-color: transparent;*/
    /*background-color: #4da6ff; NICE BLUE*/
}

.react-tabs__tab-list {
    /*background: blue;*/
    margin: 0 0 10px;
    padding: 0;
}

li.react-tabs__tab {
    border-radius: 5px 5px 0 0;
    border-color:transparent;
    color: rgba(255,255,255,.7);
    background-color: rgba(255,255,255, .2);
    margin-right:5px;
    /*border: 1px solid rgba(255,255,255,.7);*/

    display: inline-block;
    /*border: 1px solid transparent;*/
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 6px 12px;
    cursor: pointer;
}

li.react-tabs__tab--selected {
    background: #4da6ff;
    /*border-bottom: 1px solid white;*/
    /*color: #007bff;*/
    color:white;
}

.react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
}

li.react-tabs__tab:focus:after {
    background: transparent;
    /*box-shadow: 0 0 5px hsl(208, 99%, 50%);*/
    /*border-color: hsl(208, 99%, 50%);*/
    /*outline: none;*/
}

/*.react-tabs__tab:focus:after { THIS GIVES A WEIRD DEFAULT UNDERLINE FOR FOCUSED ITEMS*/
/*    content: "";*/
/*    position: absolute;*/
/*    height: 5px;*/
/*    left: -4px;*/
/*    right: -4px;*/
/*    bottom: -5px;*/
/*    background: #fff;*/
/*}*/

.react-tabs__tab-panel {
    display: none;
}

.react-tabs__tab-panel--selected {
    display: block;
}

/* End tab styling */


.fa {
    padding: 0 5px;
}
.ticket {
    background: #121212;
    color: white;
    height: 100%;
}
.view {
    width: 100%;
    height: auto;
    /*margin: 0px 5px 0px 5px;*/
    font-size: 15px;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
}

/* Save Changes button */
.save-btn-wrapper {
    top: 0;
    right: 0;
    position: fixed;
    z-index:10000;
}

.save-btn-wrapper button.k-button{
    font-size:12px;
    height: 29px;
    border-radius: 0;
}

.in-edit-wrapper {
    padding: 10px 10px 0 0;
}

/* Top level view panel */
.vp {
    flex: 1 1 100%;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: flex-start;
}

.vp-no-header {
    padding: 20px;
}

.vp-no-header > * {
    margin: 5px;
}

/** TRADE METADATA PANEL **/
#meta-vp {
    max-width: 400px;
    border-radius: 0 0 30px 30px;

    position: sticky;
    top: 30px;
    background: #262626;
    font-size: 14px;
    z-index: 1;

    -webkit-box-shadow: 0px 10px 27px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 27px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 27px 2px rgba(0,0,0,0.75);
}

#meta-vp .vp {
    align-items: center;
}

/* + or - metadata expander*/
#meta-expander {
    color: #4dc3ff;
}

#meta-expander:hover{
    cursor: pointer;
    opacity: .7;
}

.meta-div {
    padding: 15px 10px;
    /*outline: 1px solid aliceblue;*/
}

.meta-title {
    font-weight: bold;
}

#meta-vp .k-datepicker input.k-input {
    padding: 0 10px;
    height: auto;
}
#meta-vp .k-datepicker span.k-select {
    padding: 8px 10px;
}

#meta-vp .k-datepicker {
    /*width: 20%;*/
    /*min-width: 140px;*/
}

.tradeDate-wrapper {
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;
    max-width: 250px;
}

.tradeDate-left {
    /*width: 150px;*/
    width: 120px;
}

.tradeDate-right{
}

#meta-content{
    font-size: 12px;
}

#meta-content .meta-div {
    padding: 8px 5px;
}

/** View Panel banner **/
.vp-banner-wrapper {
    flex: 1 1 100%;
    margin: 0px; /* overried defaul .vp>*/
    /*padding: 10px;*/

    display: flex;
    flex-flow: row nowrap;
    justify-content: center;

    /*background-color: #d6f5d6;*/
}

.vp-banner{
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    width: 100%;
    max-width:900px;
}

.vp-banner>*{
    margin: 0 5px;
}
.vp-banner-label{
    /* Align the label to the left*/
    justify-self: flex-start;
    margin-right: auto;

    font-size: 14px;
    vertical-align: middle;
    padding:10px;
    height:100%;
    /*background-color: forestgreen;*/
    color: white;
    border-radius:0 0 5px 5px;
    /*border-bottom: 2px solid white;*/
}

.vp-banner button.k-button {
    border-radius: 0 0 5px 5px;
    color: white;
    background-color: rgba(255,255,255,.3);
    border: 0;
}
/* STICKY TOP VIEW PANEL */
#vp-sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    /*background-color: rgba(0,0,0,1);*/
    z-index: 1000;
}
/* VERTICAL WRAP (Heading) */
.vp-vertical-wrap {
    display:flex;
    flex-direction:column;
}
.vp-vertical-wrap .vp-label {
    padding: 0 0 5px 0;
    font-size: 12px;
}

/* INPUT RESET STYLING */
span.k-widget,
input.k-textbox {
    width: 100%;
}

.view .k-widget input.k-input,
input.k-textbox {
    font-size:14px;
    text-overflow: initial;
}

/* INPUT FLEXBOX WRAPPERS */
.v-combo-wrap {
    flex: 1;
    min-width: 120px;
    max-width: 160px;
}

.v-inp-wrap {
    flex: 1;
    min-width: 80px;
    max-width: 100px;
}

.v-date-wrap {
    flex: 1;
    min-width: 160px;
    max-width: 200px;
}

.v-datetime-wrap {
    flex: 1;
    min-width: 240px;
    max-width: 300px;
}

div.v-datetime-widget{
    width:100%;
}

/* Datetime Inputs */
.v-datepicker {
    padding: 0 0 0 .75rem;
}

/* BUTTON RESET STYLING */
.vp button.k-button {
    padding: 5px 10px;
    font-size: 12px;
}

/* Legs */
.vp-card{
    margin: 10px;
    flex: 1 1 25%;
    min-width: 210px;
    max-width: 300px;

    display: flex;
    flex-flow: column nowrap;
    border-radius: 10px;
    background-color: rgba(255, 255, 255, .12);

    /*border-left: 3px solid #BB86FC;*/
}

.vp-card-bare{
    flex: 1 1 auto;
    min-width: 220px;
    max-width: 300px;

    display: flex;
    flex-flow: column nowrap;
    justify-content: space-evenly;
    align-self: center;
}

.vp-card-bare> *{
    margin: 10px;
}

.vp-card-row {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    padding: 5px 10px;
}
.vp-card-row:first-child{
    padding-top: 10px;
}

.vp-card-row:last-child {
    padding-bottom: 10px;
}

.vp-card-banner{
    /*background-color: rgba(255, 255, 255, 0.12);*/
    padding: 10px;
    /*border-radius: 10px 10px 0 0; !* for some reason need to duplicate this *!*/
    color: rgba(255, 255, 255, 1);
}

.vp-card-banner-close{
    flex:1;
    text-align: right;
}

.vp-card-banner-icon {
    padding-right: 5px;
}

/** The k-icon which are clickable like x, +, **/
.k-i-add:hover,
.k-i-close:hover,
.k-icon-btn:hover {
    color: #4393e6;
    cursor: pointer;
}

.vp-card-row-left{
    width: 40px;
    text-align: right;
    color: rgba(255,255,255,.8);
}
.vp-card-row-right{
    flex:1;
    padding-left: 10px;
}

/** BROKERAGE PANELS **/
.brokerage-panel {
    flex: 1 1 30%;
    display:flex;
    flex-flow: column nowrap;
    align-items: center;
    max-width: 450px;

    padding: 0 10px;
}

.brokerage-panel .vp-card {
    margin:10px 0;
}
.brokerage-panel .vp-card-row-left {
    width: 60px;
}

/* EMAIL TABLE */
.email-table-card {
    /*width:100%;*/
    /*padding: 0 10px;*/
}

input.email-inp {
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid powderblue;
    padding: 0;
    height: auto;
    background-color: transparent;
    padding: 3px;
}

.email-table-card{
    width: 100%;
}
.email-table td{
    padding-top: 5px;
    padding-bottom: 5px;
}
.email-table td:first-child{
    padding-left: 20px;
}
.email-table td:last-child{
    padding-right: 10px;
}

.email-table tr:nth-child(even){
    /*background-color: aliceblue;*/
}



/* COLORING REPEAT ELEMENTS */

/* Styling legs */
#legs-vp, #option-vp {
    background: #121212;
}

#legs-vp .vp-banner-wrapper {
    /*border-top: 2px solid;*/
    /*border-image-slice: 1;*/
    /*border-width: 2px;*/
    /*border-image-source: linear-gradient(to right, #BB86FC, #03DAC5);*/

    background:
            linear-gradient(#121212, #121212) padding-box, /*this is your grey background*/
            linear-gradient(to right,  #03DAC5,#BB86FC) border-box;
    border-top: 2px solid transparent;
    overflow: hidden;
    border-radius:30px 30px 0 0;
}

#legs-vp .vp-banner-label {
    /*background-image: -webkit-linear-gradient(-30deg, #03DAC5 50%, #ff8533 50%);*/
    background: #03DAC5;
    color: white;
}

.vp-card-call {
    border-left: 3px solid #03DAC5;
}

.vp-card-call .vp-card-banner-label {
    color: #03DAC5;
}

.vp-card-put {
    border-left: 3px solid #BB86FC;
}

.vp-card-put .vp-card-banner-label {
    color: #BB86FC;
}

#btn-add-call {
    background-color: #03DAC5;
}

#btn-add-put {
    background-color: #BB86FC;
}

/* Styling hedge*/

#hedge-vp-wrapper {
    background:  #121212;
}

#hedge-vp {
    background:  #1a1a1a;
    border-top: 2px solid #8B8378;
    border-radius:30px 30px 0 0;
    overflow:hidden;
}

#hedge-vp .vp-banner-wrapper {
    /*border-top: 2px solid rgba(255,255,255,.4);*/
    /*border-radius:30px 30px 0 0;*/
    /*overflow:hidden;*/
}

#hedge-vp .vp-banner-label {
    /*background: #64B5F6;*/
    background: #8B8378;
}

#hedge-vp .vp-card {
    /*border-left: 3px solid #64B5F6;*/
    border-left: 3px solid #8B8378;
}

/* Coloring brokerage panel */
#brokerage-vp-wrapper {
    background: #1a1a1a;/* Same as hedge body background*/
}
#brokerage-vp {
    /*border-radius: 30px 30px 0 0;*/
    /*!*border-top: 2px solid;*!*/
    /*!*border-image-slice: 1;*!*/
    /*!*border-width: 2px;*!*/
    /*!*border-image-source: linear-gradient(to right, #BB86FC, #03DAC5);*!*/
    /*background:rgba(255,255,255,.1)*/

    /*z-index:1000;*/
    background:
            linear-gradient(black, black) padding-box, /*this is your grey background*/
            linear-gradient(to right, #4dc3ff, #fe615a) border-box;
    border-top: 2px solid transparent;
    overflow: hidden;
    border-radius:30px 30px 0 0;
}

#buy-panel .vp-banner-label {
    background: #4dc3ff;
}
#buy-panel>div:not(:first-child) {
    border-top: 3px solid #4dc3ff;
}

#sell-panel .vp-banner-label {
    background: #fe615a;
}

#sell-panel>div:not(:first-child) {
    border-top: 3px solid #fe615a;
}

/* HOVER EFFECTS */
.react-tabs__tab, button.k-button {
    transition: .15s;
}
.react-tabs__tab:hover, button.k-button:hover{
    opacity: .8;
}

/** Confirm Styling **/
.SwitchWrapper {
    position:fixed;
    top: 30px;
    left: 50%;
    transform: translate(-50%, 0);

    height: 30px;
    width: 150px;
    overflow: hidden;

    border-radius: 0 0 10px 10px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: stretch;
    background: black;

    -webkit-box-shadow: 0px 10px 27px 2px rgba(0,0,0,0.75);
    -moz-box-shadow: 0px 10px 27px 2px rgba(0,0,0,0.75);
    box-shadow: 0px 10px 27px 2px rgba(0,0,0,0.75);
}

.Switch {
    display: flex;
    flex: 1 1 auto;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    transition: .3s;

    background: black;

    div {
        display: flex;
    }
}

.Switch:hover {
    opacity: .7;
    cursor: pointer;
}

.react-tabs {
    flex-direction: column;
    display: flex;
}
.react-tabs__tab-panel--selected{
    display: flex;
    flex-grow: 1;
}

.pdf-wrapper {
    width:100%;
    height:100%;
}

.pdf-object{
    width:100%;
    height:100%;
}