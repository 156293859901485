.FormTable {
  margin: 10px 0;
  width: 100%;

  &__title {
    font-weight: bold;
    padding-top: 2px;
    color: #5f85db;
    padding-bottom: 10px;
    border-bottom: 1px solid grey;
  }

  td{
    padding: 5px 0;
    &:first-child{
      text-align: right;
      min-width: 80px;
      &:first-letter{
        text-transform: capitalize;
      }
    }

    &:nth-child(2) {
      padding-left: 20px;
    }
  }

  &__submit_button {
    display: block;
    margin: 0 auto;
    width: 100%;
  }
}