$base-theme:Bootstrap;
$skin-name:eric-dark-v1;
$swatch-name:Black;
$border-radius: 0.25rem;
$accent: #0275d8;
$secondary: #444444;
$info: #5bc0de;
$success: #5cb85c;
$warning: #f0ad4e;
$error: #d9534f;
$body-bg: #111111;
$body-color: #ffffff;
$component-bg: #111111;
$component-color: #ffffff;
$card-cap-bg: #2b2b2b;
$card-cap-color: #bbbbbb;
$series-a: #0275d8;
$series-b: #ffd246;
$series-c: #5cb85c;
$series-d: #f0ad4e;
$series-e: #e67d4a;
$series-f: #aa46be;
