.admin-wrapper {
  div.k-window-titlebar {
    padding: 10px 16px;
  }
}
.UserModal {
  &__form {
    margin: 20px;
    display: flex;
    flex-flow: column nowrap;

    > span {
      width: 300px;
      margin: 10px;
    }

    &--password {
      padding-bottom: 20px;
      border-bottom: 1px solid white;
    }
  }
}