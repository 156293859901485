.TradeForm {
  display: flex;
  align-items: flex-end;
  span.k-textbox-container, span.k-floating-label-container {
    width: 100%;
  }
  //justify-content: space-evenly;

  &__cell {
    &--input {
      width: 80px;
    }
    &--combo {
      width: 120px;
    }
    padding: 0 5px;
  }

  &__button {
    margin-right: 5px;
  }

  &__times {
    padding-bottom: 4px;
  }
}