
div.k-animation-container {
  z-index: 99999 !important;
}

.Header {
  display: flex;
  position: sticky;
  top: 0;
  z-index: 100;
  background: #111111;
  border-bottom: 1px solid black;
  padding-top: 5px;

  &__tabs {
    width: 80%;
    align-self: flex-end;
  }

  &__icons {
    width: 20%;
    padding-right: 5px;
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    font-size: 10px;
    border-radius: 5px 5px 0 0;
    align-self: flex-end;

    &:last-child {
      margin-left: 5px;
    }
  }
}