.NotesCell {
  align-items: center;
  display: flex;
  font-size: 12px;
  padding-bottom: 5px;
  //margin-left: 60px;

  &__flag {
    color: black;
    background-color: gold;
    width: 20px;
    text-align:center;
    font-weight: bold;
  }

  &__text {
    font-style: italic;
    margin-left: 20px;
  }
}