.OrderCell {
  margin: 10px 0;
  position: relative;

  &__order_string{
    color: #ffbf00;
    font-weight: bold;
  }
}

.OptionDisplay,
.RevconDisplay {
  display: flex;
  &__ticker {
    font-size: 16px;
    width: 60px;
    padding-right: 10px;
    text-align: right;
  }

  &__secondary {
    color: white;
  }
}