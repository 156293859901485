.OrderDetail {
  font-size: 15px;
  padding: 10px;
  border-radius: 10px;
  display: inline-block;
  //border:  1px solid #afbdcf;
  background: #ECECEC;

  margin: 5px 10px;
  font-weight: bold;
    box-shadow: 0 1px 2px rgba(0,0,0,0.03),
  0 2px 4px rgba(0,0,0,0.03),
  0 4px 8px rgba(0,0,0,0.03),
  0 8px 16px rgba(0,0,0,0.03),
  0 16px 32px rgba(0,0,0,0.03),
  0 32px 64px rgba(0,0,0,0.03);

  &__ticker {

  }

  &__expiration {

  }

  &__strike {

  }

  &__structure {

  }

  &__risk_rwd_ratio {

  }

  &__ratio {

  }

  &__tied {

  }

  &__delta {

  }
}