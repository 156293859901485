@import "../../Aiva_Colors";

.ChatHeader {
  position: relative;
  padding: 15px;
  height: 70px;
  border-bottom: 1px solid #d9d9d9;
  overflow: hidden;
  display: flex;
  flex-flow: row nowrap;

  &__title {
    font-weight: bold;
    padding-bottom: 10px;
  }

  &__subtitle {
    color: $text_grey;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;

    overflow: hidden;
  }

  &__people_icon {
    width: 40px;
    display: flex;
  }

  &__description {
    display: flex;
    flex-flow: column nowrap;
    overflow: hidden;
    height: 14px;
    font-size: 14px;
  }

  &__button {
    position: absolute;
    right: 10px;
    top: 16px;
    background-color: white;
    color: grey;
    border: 1px solid #c6c8ca;
  }

  div.k-icon {
    vertical-align: baseline;
    font-size: 14px;

    &:hover {
      cursor: pointer;
      color: lightblue;
    }
  }
}