.MessageActionPanel {
  display: flex;
  align-items: center;

  margin-left: 13px;
  padding: 5px;
  transition: .1s;
  color:  #004d99;
  border-radius: 5px;
  border: 1px solid transparent;

  &:hover {
    cursor: pointer;
    border: 1px solid #f2f2f2;
    background: white;
  }

  .k-icon {
    &:hover {
      color: grey;
    }
  }

  &__count {
    margin-left:5px;
    &:hover {
      text-decoration: underline;
    }
  }
}