/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

#body {
    background-color: #eceff1;
    height: 100vh;
}

/**
 * images a now responsive
 */
img {
    max-width: 100%;
    height: auto;
}
.main-header{
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);    border-radius: 3px;

}
/**
 * Limit the container in
 * width for big screens
 */
.header-container {
    width: 1300px;
    max-width: 100%;
    padding: 0px;
    margin: 0 auto;
}

button.k-button.k-bare.DashBoard__button {
    color: black;
}

/**
 * By using display: flex
 * Logo and nav are in 2 cols
 * align-items make them
 * vertically centered
 * justify-content distribute
 * horizontal spaces around
 * and flex-wrap break the
 * things in two lines in
 * small screens
 */
.main-header .header-container {
    display: flex;
    /*align-items: center;*/
    justify-content: space-between;
    flex-wrap: wrap;
}

/**
 * The followings are to
 * make things more
 * clean and airy
 * and contents centered
 */
.main-nav ul {
    margin: 0;
    text-align: center;
}
.main-nav li {
    display: inline;
}
.main-nav Button {
    padding: 20px;
}

.main-nav Button{
    height: auto;
    width: auto;
}


/* Dash Styling */
.container {
    width: 1300px;
    max-width: 100%;
    padding: 20px;
    margin: 0 auto;
    /*align-items: center;*/

}

#stat-container{
    display: grid;
    color: black;
    grid-template-areas:
            "orders-t orders-t trades-t trades-t conv conv"
            /*"orders-h trades-h conv"*/
            "orders-h orders-h trades-h trades-h mvp mvp"
            "orders-h orders-h trades-h trades-h f-order f-order"
            "orders-h orders-h trades-h trades-h f-trade f-trade"
            "chart chart chart chart timeline timeline";
    /*grid-template-rows: 50% 1fr 1fr 1fr;*/
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
    height: 40vh;
    align-items: start;
}

#orders-today-panel{
    grid-area: orders-t;
}

#trades-today-panel{
    grid-area: trades-t;
}

#conversions-panel{
    grid-area: conv;
}

#orders-history-panel{
    grid-area: orders-h;
}

#trades-history-panel{
    grid-area: trades-h;
}

#mvp-panel{
    grid-area: mvp;
}

#first-order-panel{
    grid-area:f-order;
}

#first-trade-panel{
    grid-area:f-trade;
}

.panel{
    background-color: white;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    border-radius: 5px;

    display: flex;
    flex-direction: column;
}

.with-padding{
    padding: 20px;
}

.with-padding > * {
    padding: 5px;
}

.padded{
    margin-bottom:5px;
}
/* Today stat panel */
.line-container{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}
.flex-4{
    flex: 4;
}

.flex-1{
    flex: 1;
    text-align: right;
}


.head-container {

}

.head-info > * {
    padding: 2px;
}
.head-title{
    font-weight: bold;
    font-size: 20px;
}
.head-subtitle{
    font-size: 14px;
}

.head-stat{
    font-size: 40px;
    text-align: right;
    align-self: center;
}

.head-neg{
    color:  #ff6666;
}

.head-pos{
    color: #00cc44;
}

/* Bar percentages */
.half{
    flex: 1;
    flex-direction: column;
}

.bar-pct{
    flex: 1;
    font-size: 22px;
    alig: center;
    padding: 0 10px;
}

.bar-subtitle{
    font-size: 13px;
}

.bar-right {
    flex: 4;
    padding-right: 10px;
}

/* Conversions */
.split-left{
    display: flex;
    flex-direction: row;
}

.split-left{
    flex:1;
}

.split-right{
    flex: 1;
}


.conv-info-left{
    flex: 0;
    flex-direction: column;
    padding-right: 20px;
}

.conv-info-right{
    display: flex;
    flex:1;
    flex-direction: row;
}
.circle-box{
    display: flex;
    flex: 1;
    justify-content: center;
    flex-direction: column;
}

.circle-container{
    margin: 0 10px;
}

.conv-subtitle{
    text-align: center;
    padding-top: 10px;
}

/* Detail Panel */
.dtl-bar{
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.dtl-topbar{
    border-bottom: 1px solid gainsboro;
}
.dtl-heading{
    margin-left: 10px;
}

.dtl-button{
    flex:1;
    text-align: right;
}

.dtl-container{
    display: flex;
    flex-direction: column;
}

.dtl-line{
    display: flex;
    flex-direction: row;
    text-align: center;
}

.dtl-stat{
    font-size: 36px;
    padding-top: 10px;
}

.dtl-footer{
    background-color: #f0f0f0;
}

/* Person panel */
.person-panel {
    grid-gap: 300px;
}
.pn-container{
    width: 100%;
    display: flex;
    flex-direction: row;
}

.mvp-back{
    background-image: radial-gradient( circle farthest-corner at 13.9% 28.2%,  rgba(235,164,73,1) 0%, rgba(252,236,138,1) 90% );
}

.first-order-back{
    background-color: #99ccff;
}

.first-trade-back{
    background-color: #ffcc66;
}

.pn-left{
    display: flex;
    flex: 0 1 30%;
    /*background-color: #f0f0f0;*/
    text-align: center;
    padding: 5px 0;
    flex-direction: column;
    justify-content: center;
}
.icon-wrapper{
    background-color: white;
        width: 50px;
        height: 50px;
        margin: 0 auto;
        border-radius: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
}

.pn-box{
    flex: 1;
    display: flex;
    flex-direction: row;
    padding: 10px;
    flex-wrap: wrap;
}

.pn-name{
    /*flex: 1;*/
    flex: 1 0 100%;
    text-align: center;
}

.pn-day{
    font-size: 12px;
    /*flex: 0;*/
    /*align-self:flex-end;*/
    flex: 1;
    text-align: center;
    align-self: flex-end;
}

/* Chart container*/
#chart-panel{
    grid-area: chart;
    padding: 20px;
    background-color: #00111a;
    color: white;
    height: 400px;
}


.chart-title-small{
    font-size: 10px;
    margin-bottom: 5px;
    color:  #b3b3b3;
}

.grey{
    color:  #737373;
}

#chart-header{
    padding-bottom: 20px;
    display: flex;
    flex-direction: row;
}

.chart-header-left{
    display: flex;
    flex-direction: column;
}
.chart-header-right{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex:1;
}

.chart-btn{
    display: flex;
    flex-direction:column;
    justify-content: center;
    align-items: center;
}

.chart-btn-label{
    font-size:8px;
}

.chart-btn:not(:last-child){
    margin: 0 30px;
}

.chart-dropdown{
    color:black;
    background-color:  rgb(152, 162, 219);
}

#chart-panel span.k-input, #chart-panel span.k-select{
    color:black;
}

#chart-panel span.k-dropdown{
    width:100px;
}
*{
    /*outline: 1px solid red;*/
}

#timeline-panel{
    grid-area: timeline;
    height: 400px;
    background: #00111a;
}

.dtl-topbar-dark{
    color: white;
}
#timeline-container{
}
* {
    /*outline: 1px solid red;*/
}


/* Media queries */

@media only screen and (max-width: 1000px) {
    #stat-container{
        display: grid;
        grid-template-areas:
                "orders-t trades-t"
                "orders-h trades-h"
                "conv mvp"
                "conv f-order"
                "conv f-trade"
                "chart chart"
                "timeline timeline";
        grid-template-rows: repeat(auto-fill, 1fr);
        grid-template-columns: 1fr 1fr;

        grid-gap: 20px;
        height: auto;
        align-items: start;
    }
}

@media only screen and (max-width: 600px) {

    #stat-container{
        display: grid;
        grid-template-areas:
                "orders-t"
                "trades-t"
                "conv"
                "orders-h"
                "trades-h"
                "mvp"
                "f-trade"
                "f-order"
                "chart"
                "timeline";
        grid-template-rows: repeat(auto-fill, 1fr);
        grid-template-columns: 1fr;

        grid-gap: 20px;
        height: auto;
        align-items: start;
    }

}