.TickerQuote {
  font-weight: normal;
  font-size: 12px;
  padding-right: 20px;

  &__price {

  }

  &__percent {
    &--green {
      color: lightgreen;
    }
    &--red {
      color:  #ff6666;
    }
  }
}