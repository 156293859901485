.OrderDown {
  padding-bottom: 5px;
  font-size: 12px;

  .k-grid-header th {
    background: #2C3849;
  }

  .k-grid {
    td, th {
      font-size: 12px;
      padding: 0;
      text-align: center;
    }
  }

  .k-grid {
    max-width: 900px;
  }

  &__size {
    color: grey;
  }
}