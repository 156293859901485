
.TabPanel {
  display: flex;
  justify-content: flex-start;

  div.rdTabWrapper {
    flex: 1 0 auto;
    width: auto !important;
  }
  span.rdTabBarAfter {
    display: none;
  }

  ul.rdTabBar {
    padding-right: 0 !important;
  }

  &__button_wrap {
    flex: 1 0 auto;
  }

  .k-button#{&}__button {
    padding: 0;
    text-align:left;
    width: auto;
    height: auto;
    margin-bottom: 7px;
    color: white;
  }
}