.SpreadForm {
  >div{
    //outline: 1px solid white;
  }
  margin: 10px;
  display: flex;
  flex-direction: column;

  &__row {
    display: flex;
    align-items: center;
    margin: 5px 0;
  }

  &__label {
    width: 40px;
    font-size: 14px;
  }

  &__field {
    width: 140px;
    margin-left: 10px;
  }

  &__submit_button {
    margin-top: 15px;
    font-size: 14px;
  }

}