.Depth {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin: 0 10px 10px 10px;
  border: 1px solid gray;
  border-radius: 2px;

  >div {
    overflow-x: hidden;
  }

  &__row {
    display: flex;
    padding: 3px 0;

    &:nth-child(odd) {
      background-color: #333333;
    }

    &:nth-child(even) {
      background-color: #404040;
    }

    &--inactive {
      background-image: linear-gradient(115deg, #262626 16.67%, transparent 16.67%, transparent 50%, #262626 50%, #262626 66.67%, transparent 66.67%, transparent 100%);
      background-size: 16.55px 35.49px;
      color: #cccccc;
    }

    &--traded:nth-child(even) {
      background-color: #353C51;
    }
    &--traded:nth-child(odd) {
      background-color: #47506c;
    }

    &:hover {
      cursor: pointer;
      background-color: #5f85db;
    }

    &--traded:hover {
      cursor: default;
      background-color: #006622;
    }
  }

  &__panel {
    text-align: center;
    display: flex;
    flex-direction: column;
    width: 50%;

    &--left {
      border-right: 1px solid grey;
    }
  }
}