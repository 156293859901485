.IconsCell {
  //background: yellow;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;

  &__date {
    &--create {
      color: grey;
    }

    &--update {
      color: #cccccc;
    }
    text-align: right;
    font-size: 10px;
  }
}