@media only screen and (max-width: 600px) {
  div.RoomModal {
    width: 300px;
  }
}

.RoomModal {
  padding: 25px;
  width: 500px;

  &__heading {
    padding: 15px 0;
  }

  &__close_button {
    position: absolute;
    top: 10px;
    right: 10px;
  }

  &__button_wrap {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    margin: 10px 0 0 0;
  }

  &__input_label {
    font-size: 14px;
    padding: 10px 0;
  }
}

.UsersUl {
  max-height: 400px;

  &__li {
    display: flex;
    align-items: center;
    padding: 0 10px;
    &:hover {
      background: rgba(255,255,255,.1);
    }
  }

  &__close {
    margin-left: auto;
    &:hover {
      cursor: pointer;
    }
  }
}