//@media only screen and (max-width: 650px) {
//  .Menu {
//    display: none;
//  }
//}

.Menu {
  flex: 0 1 200px;
  font-size: 14px;
  background: #2C3849;
  color:  #cccccc;
  overflow-y: auto;
  z-index: 10;

  &--hidden {
    display: none;
  }

  &--compact {
    position: absolute;
    display: block;
    height: 100vh;
  }
}