#app{
    padding:0;
    height:100%;
}

/* Login Page */
#login-logo{
    display: block;
    width: 60%;
    margin: 16px auto;
    position: relative;
}

#login-card {
    background: white;
    padding: 26px;
}

#login-wrapper{
    position: absolute;
    width: 320px;
    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    -webkit-box-shadow: 14px 17px 33px -7px rgba(0,0,0,0.75);
    -moz-box-shadow: 14px 17px 33px -7px rgba(0,0,0,0.75);
    box-shadow: 14px 17px 33px -7px rgba(0,0,0,0.75);
    background-color: white;
}
#login-banner{
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: #133b61;
}
#login-button{
    width: 100%;
    margin: 0 auto;
    background-color: #133b61;
}

#login-wrapper .k-form{
    padding: 0px 32px 32px 32px;
}

#error-message {
    color: red;
    padding-bottom: 10px;
}

#login-background{
    z-index: -1000;
    position: fixed;
    width: 100%;
    height: 100%;
    background: url("../../../images/nyc.jpeg") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
}