.RoomGroupHeader {
  padding: 15px 10px;
  display: flex;
  align-items: center;

  .k-icon {
    margin-right: 4px;
  }

  &__button {
    margin-left: auto;

    div {
      border-radius: 3px;
      font-size: 26px;
      padding: 0 6px;
      &:hover {
        cursor: pointer;
        background: rgba(255,255,255,.3);
      }
    }
  }
}