.ChatBoard, .Thread {
  height: calc(100% - 160px);
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}
::-webkit-scrollbar-track {
  background: white;
}
::-webkit-scrollbar-thumb {
  background: #ddd;
}